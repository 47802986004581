import React from 'react'
import CreatePostPurchase from './createPostPurchase'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function PostPurchaseOfferItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <CreatePostPurchase {...props} id={id} />
}

export default PostPurchaseOfferItem;